a.scroll-to-top {
  position: fixed;
  right: 50px;
  bottom: 50px;
  z-index: 100;
  display: inline-block;
  width: 50px;
  height: 50px;
  border: 1px solid $primary-color;
  border-radius: 50%;
  background-color: $primary-color;
  text-align: center;
  opacity: 0;
  cursor: pointer;
  transition: all 0.4s ease-in-out 0s;

  &:hover {
    border: 1px solid $primary-color;
    background-color: white;
    text-decoration: none;

    &:before {
      color: $primary-color;
    }
  }

  &.move {
    bottom: 150px;

    @media only screen and (max-width: 840px) {
      bottom: 85px;
    }
  }

  &:before {
    position: relative;
    top: 11px;
    right: 1px;
    display: inline-block;
    width: 50px;
    height: 50px;
    color: white;
    content: '\f077';
    font-weight: 700;
    font-size: 20px;
    font-family: 'Font Awesome 5 Free';
  }

  @media only screen and (max-width: 768px) {
    right: 25px;
    bottom: 50px;
  }
}