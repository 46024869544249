
main {
  .team-members{
    display: flex;
    flex-wrap: wrap;
  }

  .member{
    position: relative;
    width: calc(50% - 100px);
    margin: 0 100px 100px 0;
    padding-bottom: 40px;
    box-shadow: 0 0 20px rgba(0, 0, 0, .15);
    max-height: 300px;
    overflow: hidden;
    transition: max-height .5s ease-in-out;

    &.open{
      max-height: 9999px;
    }

    > div:after{

      display: table;
      content: '';
      clear: both;
    }

    &:nth-child(4n){
      margin-right: 0;
    }

    img{
      float: left;
      width: 90px;
      height: 90px;
    }

    h3{
      display: inline-block;
      padding: 20px 0 0 20px;
      margin: 0;
      background: #333;
      width: calc(100% - 90px);
      color: #fff;
    }

    h4{
      display: inline-block;
      padding: 10px 0 13px 20px;
      margin: 0;
      font-size: 18px;
      width: calc(100% - 90px);
      font-style: italic;
      background: #333;
      color: #fff;
    }

    .member-details{
      padding: 20px;

    }

    button.read-more{
      position: absolute;
      bottom: 0;
      color: $primary-color;
      text-decoration: none;
      font-weight: 700;
      font-size: 14px;
      text-transform: uppercase;
      letter-spacing: 1px;
      cursor: pointer;
      border: 0;

      margin: 0;
      width: 100%;
      padding: 70px 20px 20px;
      background: linear-gradient(0deg, #fff 46%, transparent 90%);


      &:hover,
      &:focus {
        color: $tertiary-color;
      }

      &:visited{
        color: $primary-color;
      }
    }
  }
}

.member-details{
  margin-bottom: 150px;
  .overview{
    display: inline-block;
    width: 20%;
    margin-right: 50px;
    padding-right: 50px;
    border-right: 2px solid #efefef;

    > img{
      width: 100%;
    }
  }
  .details{
      display: inline-block;
      width: calc(100% - calc(20% + 100px));
      vertical-align: top;
  }
}

a.return-link{
  color: $primary-color;
  text-decoration: none;
  font-weight: 700;
  font-size: 24px;
  text-transform: uppercase;
  letter-spacing: 1px;

  &:hover,
  &:focus {
    color: $tertiary-color;
  }

  &:visited{
    color: $primary-color;
  }
}
