section.banner {
  position: relative;
  z-index: 0;

  .slides {
    padding-bottom: 30px;
    background: black;

    .owl-stage-outer {
      padding-bottom: 40px;
      margin-bottom: -30px;

      @include medium-up {
        padding-bottom: 0;
      }

      .owl-item {
        &:first-of-type {
          margin-left: 0;
        }
      }
    }
  }

  .owl-nav {
    padding: 0 20px;

    @include medium-up {
      position: absolute;
      top: 50%;
    }
  }

  .owl-dots {
    top: -10px;
  }

  .slide {
    width: 100%;
    padding-bottom: 160px;
    height: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    @include medium-up {
      max-height: calc(100vh - 370px);
      min-height: 720px;
      padding: 0;
    }

    .slide-content {
      position: relative;
      z-index: 1;
      padding: 30px 20px;
      color: white;
      text-align: center;

      @include medium-up {
        position: absolute;
        top: 50%;
        left: 50%;
        display: flex;
        align-items: center;
        width: 100%;
        max-width: 1024px;
        padding: 0;
        padding: 0 80px;
        text-align: left;
        transform: translate(-50%, -50%);
      }

      .featured-image {
        margin-bottom: 50px;
        border: 2px solid #fff;

        @include medium-up {
          max-width: 30%;
          margin-right: 50px;
          margin-bottom: 0;
        }

        img {
          width: 100%;
          margin: 0 auto;
        }

        &.hide {
          display: none;
        }
      }

      .caption {
        display: block;

        @include medium-up {
          max-width: 70%;
        }

        .title {
          margin-top: 0;
          margin-bottom: 10px;
          font-size: 36px;
          font-style: normal;
          font-weight: 100;
          font-weight: $heavy;
          color: white;

          @include medium-up {
            font-size: 56px;
          }
        }

        .blurb {
          width: 100%;
          padding-bottom: 30px;
          margin: auto;
          font-size: 20px;
          font-weight: $light;
          line-height: 30px;

          @include medium-up {
            padding-bottom: 15px;
          }
        }
      }
    }
  }
}

.cycle-slideshow {
  // padding-bottom: 80px;
  z-index: 0;

  @include medium-up {
    padding-bottom: 0;
  }
}

.cycle-pager {
  position: absolute;
  bottom: 0;
  left: 50%;
  z-index: 999;
  max-width: calc(100% - 200px);
  padding: 80px 0;
  text-align: center;
  transform: translateX(-50%);

  @include medium-up {
    display: none;
  }

  span {
    padding: 0 7px;
    margin: 0 7px;
    font-size: 18px;
    color: $slider-pagers;
    text-decoration: none;
    cursor: pointer;
    background: $slider-pagers;
    border-radius: 50px;
    opacity: 0.4;

    &.cycle-pager-active {
      color: white;
      background: white;
      opacity: 1;
    }
  }
}

.prev-next-pager {
  position: absolute;
  bottom: 0;
  left: 50%;
  z-index: 998;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 0;
  max-width: 1150px;
  padding: 45px 20px;
  transform: translate(-50%, -50%);

  @include medium-up {
    top: 50%;
    padding: 20px;
  }

  span {
    color: white;
    cursor: pointer;

    &.prev,
    &.next {
      &:before {
        font-family: 'Font Awesome 5 Free';
        font-size: 48px;
        font-weight: 900;
        color: $slider-buttons;
        opacity: 0.15;

        @include medium-up {
          font-size: 72px;
        }
      }

      &:hover,
      &:focus {
        &:before {
          opacity: 1;
        }
      }
    }

    &.prev {
      &:before {
        content: '\f053';
      }
    }

    &.next {
      &:before {
        content: '\f054';
      }
    }
  }
}