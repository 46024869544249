

main {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0 0 20px 0;
    font-style: $regular;
    color: $heading-text-dark;
  }

  h1 {
    font-size: 48px;
    font-weight: $bold;
    color: $primary-color;
  }

  h2 {
    font-size: 30px;
    font-weight: 700;
    font-weight: $bold;
  }

  h3 {
    font-family: $secondary-font;
    font-size: 24px;
    font-weight: $bold;
  }

  h4 {
    font-size: 24px;
    font-weight: $bold;
  }

  h5 {
    font-size: 20px;
    font-weight: $regular;
  }

  h6 {
    margin: 16px 0;
    font-size: 20px;
    font-style: italic;
    font-weight: $regular;
  }

  p,
  ul,
  ol {
    margin: 0 0 20px;
    font-size: 20px;
    line-height: 30px;
    color: $paragraph-text;
  }

  a {
    color: $primary-color;
    text-decoration: none;

    &:hover,
    &:focus {
      color: $primary-color;
      text-decoration: underline;
    }

    &:visited {
      color: inherit;
    }
  }

  > br:last-of-type {
    display: none !important;
  }

  ul {
    padding-left: 20px;
    font-size: 18px;
    list-style: none;

    li:not([class]) {
      &:before {
        position: relative;
        top: -3px;
        left: -5px;
        display: inline-block;
        font-family: 'Font Awesome 5 Free';
        font-size: 3px;
        font-weight: $bold;
        color: #0062cf;
        content: '\f111';
      }

      ul {
        margin-bottom: 0;
        font-size: 16px;

        li:not([class]):before {
          color: #b2b2b2;
        }

        ul {
          li:not([class]):before {
            color: #d6d6d6;
          }
        }
      }
    }
  }

  ol {
    padding-left: 20px;
    font-size: 18px;
    list-style: none;

    counter-reset: numbers;

    li {
      position: relative;

      counter-increment: numbers;

      &:before {
        position: relative;
        left: -5px;
        display: inline-block;
        font-size: 18px;
        font-weight: $bold;
        color: $primary-color;
        content: counter(numbers)'.';
      }

      ol {
        margin-bottom: 0;
        font-size: 16px;

        li {
          &:before {
            position: relative;
            top: -3px;
            left: -5px;
            display: inline-block;
            font-family: 'Font Awesome 5 Free';
            font-size: 3px;
            font-weight: $bold;
            color: #0065cf;
            content: '\f111';
          }
        }
      }
    }
  }

  blockquote {
    position: relative;
    padding: 20px 25px;
    margin-right: 0;
    margin-left: 0;
    font-size: 24px;
    font-style: italic;
    color: $primary-color;
    border: 3px solid $figure-caption-border;

@include medium-up {
      float: right;
      max-width: 40%;
      padding: 40px 50px;
      margin: 0 0 20px 50px;
      clear: both;
      font-size: 30px;
      line-height: 45px;

      p {
        font-size: 30px;
      }
    }

    &:before,
    &:after {
      position: absolute;
      width: 3px;
      height: 67%;
      background: $figure-caption-border-spacers;
      content: '';
    }

    &:before {
      bottom: -3px;
      left: -3px;
    }

    &:after {
      top: -3px;
      right: -3px;
    }

    p {
      margin: 0;
      font-weight: $light;
      line-height: 45px;
      color: $primary-color;
    }
  }

  figure {
    figcaption {
      position: relative;
      padding: 30px;
      margin-top: 15px;
      font-size: 16px;
      font-weight: $regular;
      color: $figure-caption-color;
      text-align: center;
      background: transparent;
      border: 3px solid $figure-caption-border;

      &:before,
      &:after {
        position: absolute;
        width: 3px;
        height: 50%;
        background: $figure-caption-border-spacers;
        content: '';
      }

      &:before {
        bottom: -3px;
        left: -3px;
      }

      &:after {
        top: -3px;
        right: -3px;
      }
    }
  }
}

.button {
  padding: 25px 0;
  font-size: 16px;
  text-transform: uppercase;

  &.show {
    display: block;
  }

  &.hide {
    display: none;
  }

  a {
    padding: 15px;
    letter-spacing: 1px;
    color: white;
    text-decoration: none;
    background: $button-color;
    border-radius: 50px;
    transition: 0.5s;

@include medium-up {
      padding: 15px 60px;
    }

    &:hover,
    &:focus {
      color: white;
      text-decoration: none;
      background-color: #555758;
      border-color: white;
    }

    &:visited {
      color: white;
    }
  }
}
