.branding {
  position: relative;
  display: block;
  width: 100%;
  padding: 10px 20px 20px;
  background: $header-background-color;

@include medium-up {
    position: relative;
    top: 0;
    width: 20%;
    height: 100%;
    padding: 0;
  }

  > div {
    display: block;
    width: 100%;
    height: 100%;
  }

  a {
    position: relative;
    height: 145px;
    margin: auto;
    transform: none;
@include medium-up {
      position: absolute;
      top: 50%;
      z-index: 1;
      display: inline-block;
      width: 400px;
      max-width: 100%;
      transform: translateY(-50%);
    }

    .logo {
      display: block;
      width: 100%;
      height: 50px;
      max-width: none;
      background: url(/themes/athena/assets/dist/images/logo-PacificManagement.svg) no-repeat;
      background-position: center;
      background-size: contain;
@include medium-up {
        display: inline-block;
        height: 100%;
        background-position: 100% 60%;
      }
    }
  }
}
